<template>
  <div>
    <div class="wrapper" :class="!isApp ? 'appTop' : ''">
      <div class="coll-box">
        <div class="choose-title">
          <div class="" @click="chooseAllMeth">{{ !chooseAll ? '全选' : '取消全选' }}</div>
          <div class="" style="color: rgba(255, 255, 255, 0.7)">仅展示支持分解的藏品</div>
        </div>
        <div class="coll-item" v-for="(item, index) in list" :key="index">
          <div class="item-left" @click="chooseColl(index, 1)">
            <div class="icon">
              <van-icon :name="item.cheacked ? coll_choose : coll_no_choose" class="" />
            </div>
            <div class="flex">
              <div class="img-box">
                <img :src="item.image_icon" alt="" />
                <div class="num">x{{ item.cnt }}</div>
              </div>
              <div class="content">
                <div class="display_name">{{ item.display_name }}</div>
                <div class="items_name">{{ item.items_name }}</div>
              </div>
            </div>
          </div>
          <div class="addnum" v-if="item.cheacked">
            <van-stepper
              v-model="item.addnum"
              :max="item.cnt"
              @change="chooseColl(index, 2)"
              class="stepper"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="fixed-bootm">
      <div class="flex bt-head">
        <div class="">
          <div class="haschoose">
            已选择 <span>{{ allnum }}</span> 张
          </div>
          <div class="fen">
            分解为 <span>{{ totalpoint }}</span> 积分
          </div>
        </div>
        <div class="flex">
          <div class="btn-none" @click="cancel">取消</div>
          <div class="btn" @click="decomposition">分解</div>
        </div>
      </div>
      <div class="my-point" @click="toMyPoint">
        我的积分：{{ userpoint }} <van-icon name="arrow" />
      </div>
    </div>
    <div v-if="!list.length" class="dataNullBox">
      <div>
        <img src="@/imgs/comm/dataNull.png" alt="" srcset="" />
      </div>
      <div class="text">暂无可分解藏品</div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from 'vue';
import { decompose, getuserscores, getdecomposelist } from '@/service/order.js';
import { nftUtils } from '@/utils';
import { Toast } from 'vant';
import { useRouter } from 'vue-router';
export default {
  setup() {
    const router = useRouter();
    const state = reactive({
      list: [],
      hasChoose: [],
      allnum: 0,
      totalpoint: 0,
      userpoint: 0,
      isApp: nftUtils.isInApp(),
      chooseAll: false
    });
    onMounted(async () => {
      getCollections();
      userscores();
    });
    const getCollections = async () => {
      const { data } = await getdecomposelist();
      state.list = data.map(item => {
        return { ...item, addnum: item.cnt, cheacked: false };
      });
    };

    // 分解
    const decomposition = async () => {
      console.log(state.hasChoose);
      if (state.hasChoose.length == 0) {
        Toast('请选择分解藏品');
        return;
      }
      const { status, msg, data } = await decompose({ decompose: state.hasChoose });
      if (status == 0) {
        Toast(`已成功分解${data}积分`);
        router.go(-1);
        getCollections();
        userscores();
        state.allnum = 0;
        state.totalpoint = 0;
        state.hasChoose = [];
      } else {
        Toast(msg);
      }
    };

    //获取我的积分
    const userscores = async () => {
      const { data } = await getuserscores();
      console.log(data, '...');
      state.userpoint = data;
    };

    //全选
    const chooseAllMeth = () => {
      state.chooseAll = !state.chooseAll;
      state.list.map(item => {
        item.cheacked = state.chooseAll ? true : false;
      });

      chooseColl(0, 3);
    };

    //选择藏品
    const chooseColl = async (index, type) => {
      type == 1 && (state.list[index].cheacked = !state.list[index].cheacked);
      state.allnum = 0;
      state.totalpoint = 0;
      state.hasChoose = [];
      state.list.map(item => {
        if (item.cheacked) {
          type == 3 && (item.addnum = item.cnt);
          state.allnum += item.addnum;
          state.totalpoint += item.addnum * item.points;
          state.hasChoose.push({
            token_id: item.token_id,
            number: item.addnum
          });
        }
      });
    };

    //取消
    const cancel = () => {
      router.go(-1);
    };

    //my
    const toMyPoint = () => {
      router.push({ path: '/myPoint' });
    };

    return {
      ...toRefs(state),
      getCollections,
      chooseColl,
      userscores,
      chooseAllMeth,
      decomposition,
      cancel,
      toMyPoint,
      coll_no_choose: require('../imgs/icon/coll_no_choose.png'),
      coll_choose: require('../imgs/icon/coll_choose.png')
    };
  }
};
</script>
<style>
.addnum .van-stepper__input {
  margin: 0px;
  background: #1c172a;
}
</style>

<style lang="scss" scoped>
@import '../styles/mixin.scss';
.dataNullBox {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  img {
    width: 150px;
    height: 110px;
  }

  .text {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 400;
  }
}
.choose-title {
  display: flex;
  padding: 12px 0;
  border-bottom: 1px solid #494359;
  justify-content: space-between;
  font-size: 12px;
}
.appTop {
  padding-top: 70px !important;
}
.fixed-bootm {
  position: fixed;
  bottom: 0px;
  width: 100%;
  box-sizing: border-box;
  padding: 11px 14px 11px;
  background-color: $bc;
  .bt-head {
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;

    border-bottom: 1px solid #494359;
    .haschoose {
      font-size: 12px;
      span {
        color: $primary;
      }
    }
    .fen {
      font-size: 16px;
      span {
        color: $primary;
      }
    }
    .btn-none {
      border-radius: 4px;
      border: 1px solid rgba(255, 255, 255, 0.5);
      width: 90px;
      height: 34px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 9px;
      font-size: 16px;
      font-weight: 500;
    }
    .btn {
      width: 90px;
      height: 34px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
      border-radius: 4px 4px 4px 4px;
      font-size: 16px;
      color: #1c172a;
      font-weight: 500;
    }
  }
  .my-point {
    padding-top: 12px;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.7);
  }
}
* {
  box-sizing: border-box;
}
.coll-box {
  padding-bottom: 110px;
}
.stepper {
  --van-stepper-background-color: #494359;
  --van-stepper-button-disabled-color: #494359;
  --van-stepper-button-icon-color: #fff;
  --van-stepper-input-text-color: #fff;
  --van-stepper-input-disabled-background-color: #ffffff;
  border: 1px solid rgba(255, 255, 255, 0.7);
  border-radius: 5px;
  // height: 26px;
  overflow: hidden;
  font-size: 0px;
}

.flex {
  display: flex;
}
.wrapper {
  padding: 14px;
}
.coll-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 27px 0 20px 0;
  border-bottom: 1px solid #494359;
  .item-left {
    display: flex;
    align-items: center;
  }
  .icon {
    width: 28px;
    height: 28px;
    font-size: 22px;
  }
  .img-box {
    position: relative;
    font-size: 0px;
    img {
      width: 70px;
      border-radius: 4px;
    }
    .num {
      position: absolute;
      top: 0;
      left: 0;
      font-size: 12px;
      border-radius: 4px 0 4px 0;
      padding: 0 6px;
      background: rgba(28, 23, 42, 0.8);
    }
  }
  .content {
    margin-top: 12px;
    margin-left: 12px;
  }
  .display_name {
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
  }
  .items_name {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.7);
    line-height: 26px;
  }
}
</style>
